import React from "react"
import { useNavigate } from "react-router-dom"
import ProjectsCSS from "./Projects.module.css"
import ProjectsData from "../../data/projects.json"

function Projects() {
	const navigate = useNavigate()
	const route = (path: string) => {
		let fullPath = `./${path}`
		navigate(fullPath)
	}

	const determineRoute = (project: {
		imageSrc: string
		imageAlt: string
		overlayText: string
		path: string
	}) => {
		let existingProjects = 0
		ProjectsData.forEach((item) => {
			if (item.workplace === project.path) {
				existingProjects += 1
			}
		})
		if (existingProjects === 1) {
			const foundItem = ProjectsData.find(
				(item) => item.workplace === project.path
			)
			if (foundItem) {
				route(`${project.path}/${foundItem.path}`)
			} else {
				route(project.path)
			}
		} else {
			route(project.path)
		}
	}

	const recentProjects = [
		{
			imageSrc: "images/workplace/headsight.png",
			imageAlt: "Headsight",
			overlayText:
				"Headsight is the leading provider of combine header height control and corn row guidance solutions for clients worldwide.",
			path: "headsight",
		},
		{
			imageSrc: "images/workplace/poolstore.png",
			imageAlt: "The Pool Store",
			overlayText:
				"The Pool Store is northern Indiana's Largest Fiberglass Pool Dealer.",
			path: "poolstore",
		},
		{
			imageSrc: "images/workplace/avanade.png",
			imageAlt: "Avanade",
			overlayText:
				"A joint venture between Accenture and Microsoft, Avanade brings specialized consulting to the digital world.",
			path: "avanade",
		},
		{
			imageSrc: "images/workplace/firefly.png",
			imageAlt: "Firefly",
			overlayText:
				"Leaders of RV integration for creating Smart Home features.",
			path: "firefly",
		},
		{
			imageSrc: "images/workplace/korey.png",
			imageAlt: "Korey",
			overlayText:
				"Developing as a software engineer professionally since 2019.",
			path: "korey",
		},
	]

	return (
		<div className={ProjectsCSS["page-styles"]}>
			<div className={ProjectsCSS["content"]}>
				<div className={ProjectsCSS["top-section"]}>
					<h1>Recent Projects</h1>
					<div className="tick"></div>
					<span>
						<p className={ProjectsCSS["reading-section"]}>
							Select a tile from somewhere I've worked or my personal tile to
							see projects I've worked on.
						</p>
						<p className={ProjectsCSS["reading-section"]}>
							Want to see all projects?{" "}
							<span
								className={ProjectsCSS["inline-link"]}
								onClick={() => route("all")}
							>
								Click here
							</span>
							.
						</p>
					</span>
				</div>
				<div className={ProjectsCSS["bottom-section"]}>
					<div className={ProjectsCSS["grid-section"]}>
						{recentProjects.map((project, index) => {
							return (
								<div className={ProjectsCSS["spacer"]} key={index}>
									<div className={ProjectsCSS["grid-item"]}>
										<img
											className={ProjectsCSS["grid-image"]}
											src={project.imageSrc}
											alt={project.imageAlt}
										/>
										<div className={ProjectsCSS["overlay"]}>
											<p className={ProjectsCSS["overlay-text"]}>
												{project.overlayText}
											</p>
											<div
												className={ProjectsCSS["overlay-button"]}
												onClick={() => determineRoute(project)}
											>
												View Projects
											</div>
										</div>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Projects
