import { useForm } from '@formspree/react';
import { IonIcon } from '@ionic/react';
import { checkmarkDoneOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ContactCSS from './Contact.module.css';

function Contact() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [state, handleSubmit] = useForm('xknpbyld');

  const navigate = useNavigate(); 
  const routeHome = () => { 
    let path = '/'; 
    navigate(path);
  }

  const submitForm = (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = { name, email, message };
    handleSubmit(form);
  }

  return (
    <div className={ ContactCSS['page-styles'] }>
      <div className={ ContactCSS['content'] }>
        <div className={ ContactCSS['top-section'] }>
          <h1>Contact</h1>
          <div className='tick'></div>
          <p className={ ContactCSS['reading-section'] }>
            Have a question or comment? Feel free to Contact me using form below and I will get back to you as soon as possible.
          </p>
        </div>
        <div className={ ContactCSS['bottom-section'] }>
          {
            !state.succeeded ?
            <form className={ ContactCSS['form'] } onSubmit={ submitForm }>
              <label className={ ContactCSS['input-label'] } htmlFor='name'>Name</label>
              <input 
                disabled={ state.submitting }
                type='text' 
                id='name'
                required
                value={ name }
                onChange={ (e) => setName(e.target.value) }
                className={ ContactCSS['text-input'] }
              />
              <label className={ ContactCSS['input-label'] } htmlFor='email'>Email</label>
              <input 
                disabled={ state.submitting }
                type='email'
                id='email'
                required
                value={ email }
                onChange={ (e) => setEmail(e.target.value) }
                className={ ContactCSS['text-input'] }
              />
              <label className={ ContactCSS['input-label'] } htmlFor='message'>Message</label>
              <textarea 
                disabled={ state.submitting }
                id='message'
                required
                value={ message }
                onChange={ (e) => setMessage(e.target.value) }
                className={ ContactCSS['message-area'] }
              />
              {
                !state.submitting ?
                <button 
                  className={ ContactCSS['submit-button'] } 
                  type='submit'>
                    Submit
              </button> :
              <button 
                disabled
                className={ ContactCSS['submit-button-disabled'] } 
                type='submit'>
                  Sending...
              </button>
              }
            </form> : 
            <div className={ ContactCSS['form-submitted'] }>
              <IonIcon 
                  className={ ContactCSS['submitted-logo'] } 
                  icon={ checkmarkDoneOutline } />
              <h2>Thank You!</h2>
              <p>Your form was submitted successfully.</p>
              <div 
                className={ ContactCSS['acknowledge-button'] }
                onClick={ routeHome }>
                Okay
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default Contact;
