import React from 'react';
import Routing from './Routing';
import Navbar from './navbar/Navbar';
import Triangles from './assets/Triangles';

function App() {
  return (
    <>
      <Triangles />
      <Navbar />
      <Routing />
    </>
  );
}

export default App;
