import React, { useEffect, useState } from 'react';
import ProjectDetailsCSS from './ProjectDetails.module.css';
import { useParams } from 'react-router-dom';
import ProjectsData from '../../data/projects.json';
import { Project } from '../../interfaces/project.interface';

function ProjectDetails() {
  const [projectData, setProjectData] = useState<Project | null>();
  const { workplace, project } = useParams();

  useEffect(() => {
    function getPageData() {
      const result = ProjectsData.find(projectItem => (projectItem.workplace === workplace) && (projectItem.path === project))
      return result ? result : null;
    }

    if (workplace && project) {
      const result = getPageData();
      if (result) setProjectData(result);
    } else {
      setProjectData(null);
    }
  }, [workplace, project])

  return (
    <div className={ ProjectDetailsCSS['page-styles'] }>
      <div className={ ProjectDetailsCSS['content'] }>
        <div className={ ProjectDetailsCSS['top-section'] }>
          { projectData && projectData.site ?
              <a href={ projectData.site } className={ ProjectDetailsCSS['link'] } target="_blank" rel="noreferrer">{ projectData.name }</a> :
              <h1>{ projectData ? projectData.name : null }</h1>
          }
          <div className='tick'></div>
          {projectData && <img src={ projectData.image } alt={ projectData?.name } className={ ProjectDetailsCSS['project-image'] } />}
        </div>
        <div className={ ProjectDetailsCSS['bottom-section'] }>
          <h2>Project Overview</h2>
          {
            projectData && projectData.overview.map((string, index) => {
              return (
                <div key={ index } className={ ProjectDetailsCSS['reading-section'] }>
                  { string }
                </div>
              )
            })
          }
          <h2>Tools Used</h2>
          <div className={ ProjectDetailsCSS['skill-group'] }>
            {
              projectData && projectData.tools.map((skill) => {
                return (
                  <div className={ ProjectDetailsCSS['skill'] } key={ skill }>{ skill }</div>
                )
              })
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetails;
