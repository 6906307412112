import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import NotFoundCSS from './NotFound.module.css';
import { IonIcon } from '@ionic/react';
import { logoGithub, logoLinkedin, mail } from 'ionicons/icons';

const REROUTE = false;

function NotFound() {
  const navigate = useNavigate(); 
  const route = (path: string) => { 
    let fullPath = `./${ path }`; 
    navigate(fullPath);
  }

  return (
    REROUTE
    ? <Navigate to='/' />
    : <div className={ NotFoundCSS['page-styles'] }>
        <div className={ NotFoundCSS['welcome-box'] }>
          <h1 className={ NotFoundCSS['heading'] }>Page Not Found</h1>
          <p className={ NotFoundCSS['description'] }>The page you are looking for doesn't exist or an error occurred.</p>
          <div className={ NotFoundCSS['home-button'] } onClick={ () => route('') }>Go Home</div>
        </div>
        <div className={ NotFoundCSS['social-menu'] }>
          <a href='https://www.linkedin.com/in/korey-madden-41128613b/' className={ NotFoundCSS['social-link'] } target="_blank" rel="noreferrer">
            <IonIcon className={ NotFoundCSS['social-logo'] } icon={ logoLinkedin } />
          </a>
          <a href='https://github.com/koreymadden' className={ NotFoundCSS['social-link'] } target="_blank" rel="noreferrer">
            <IonIcon className={ NotFoundCSS['social-logo'] } icon={ logoGithub } />
          </a>
          <a href='mailto:maddenkorey@gmail.com' className={ NotFoundCSS['social-link'] }>
            <IonIcon className={ NotFoundCSS['social-logo'] } icon={ mail } />
          </a>
        </div>
    </div>
  );
}

export default NotFound;
