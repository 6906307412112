import React from "react"
import { useNavigate } from "react-router-dom"
import AboutCSS from "./About.module.css"

function About() {
	const navigate = useNavigate()
	const route = (path: string) => {
		let fullPath = `/${path}`
		navigate(fullPath)
	}

	const skills = [
		"JavaScript",
		"HTML5",
		"CSS3",
		"React.js",
		"Angular.js",
		"Angular",
		"TypeScript",
		"React Native",
		"Node.js",
		"GIT",
		"GitHub",
		"Firebase",
		"Chrome Developer Tools",
		"Microsoft Azure Fundamentals",
		"Figma",
	]

	return (
		<div className={AboutCSS["page-styles"]}>
			<div className={AboutCSS["content"]}>
				<div className={AboutCSS["top-section"]}>
					<h1>About Me</h1>
					<div className="tick"></div>
					<p className={AboutCSS["reading-section"]}>
						Here you will learn more information about me, in terms of what I
						can do with my current skills to help improve your business or
						project.
					</p>
				</div>
				<div className={AboutCSS["bottom-section"]}>
					<div className={AboutCSS["left-section"]}>
						<h2>Get to know me!</h2>
						<p className={AboutCSS["reading-section"]}>
							I'm currently a{" "}
							<span style={{ fontWeight: "bold" }}>Front-End Developer</span>{" "}
							working on various types of projects in different industries. You
							can learn about more some of the projects I've worked on under the{" "}
							<span
								className={AboutCSS["inline-link"]}
								onClick={() => route("projects")}
							>
								Projects
							</span>{" "}
							page.
						</p>
						<p className={AboutCSS["reading-section"]}>
							I am also passionate about learning more{" "}
							<span style={{ fontWeight: "bold" }}>back-end</span> technologies
							to evolve my career and become a{" "}
							<span style={{ fontWeight: "bold" }}>Full-Stack Developer</span>.
							Reach out to me on LinkedIn, email, or the{" "}
							<span
								className={AboutCSS["inline-link"]}
								onClick={() => route("contact")}
							>
								Contact
							</span>{" "}
							form.
						</p>
						<div
							className={AboutCSS["contact-button"]}
							onClick={() => route("contact")}
						>
							Contact
						</div>
					</div>
					<div className={AboutCSS["right-section"]}>
						<h2>Technical Skills</h2>
						<div className={AboutCSS["skill-group"]}>
							{skills.map((skill) => {
								return (
									<div className={AboutCSS["skill"]} key={skill}>
										{skill}
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default About
