import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/home/Home';
import About from './pages/about/About';
import Projects from './pages/projects/Projects';
import ProjectDetails from './pages/project-details/ProjectDetails';
import WorkplaceDetails from './pages/workplace-details/WorkplaceDetails';
import NotFound from './pages/not-found/NotFound';
import ProjectsNavbar from './pages/projects/projects-navbar/ProjectsNavbar';
import Contact from './pages/contact/Contact';

function Routing() {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/about' element={<About />} />
      <Route path='/projects' element={<ProjectsNavbar />}>
        <Route index element={<Projects />} />
        <Route path=':workplace' element={<WorkplaceDetails />} />
        <Route path=':workplace/:project' element={<ProjectDetails />} />
      </Route>
      <Route path='/contact' element={<Contact />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
}

export default Routing;
