import React, { useEffect, useState } from 'react';
import NavbarCSS from './Navbar.module.css';
import { NavLink } from 'react-router-dom';
import MainLogo from '../assets/png/k-logo-primary-white.png';
import MobileLogo from '../assets/png/korey-official-primary-fill.png';
import { IonIcon } from '@ionic/react';
import { menuOutline, personOutline, codeOutline, mailOutline, homeOutline } from 'ionicons/icons';

function Navbar() {
  const [mobileNav, setMobileNav] = useState(false);
  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', determineNav);
    determineNav();
  }, [])
  
  const determineNav = () => {
    window.innerWidth < 600 ? setMobileNav(true) : setMobileNav(false);
  }

  return (
    mobileNav ?
    <nav className={ mobileNav ? NavbarCSS['mobile-nav'] : NavbarCSS['full-nav'] }>
      <NavLink 
        to='/' 
        className={({ isActive }) => (isActive ? NavbarCSS.active : NavbarCSS.inactive)}>
        <div className={ NavbarCSS['circle-shadow'] }>
          <img src={ MainLogo } alt='Logo' width={ 80 } height={ 80 } />
        </div>
      </NavLink>
      <div onClick={ () => setMenuState((state) => !state) }>
        <IonIcon className={ NavbarCSS['menu-icon'] } icon={ menuOutline } />
      </div>
      <div 
        className={ menuState ? NavbarCSS['dark-layer'] : `${NavbarCSS['dark-layer']} ${NavbarCSS['hide-layer']}` } 
        onClick={ () => setMenuState(false) }>
        <div className={ NavbarCSS['menu-layer'] } onClick={ (e) => e.stopPropagation() }>
          <h2 className={ NavbarCSS['menu-heading'] }>
            <NavLink 
              to='/' 
              className={({ isActive }) => (isActive ? NavbarCSS.active : NavbarCSS.inactive)} 
              onClick={ () => setMenuState(false)}>
              <img src={ MobileLogo } alt='Logo' height={ 100 } />
            </NavLink>
          </h2>
          <ul className={ NavbarCSS['menu-list'] }>
            <li onClick={ () => setMenuState(false) }>
              <NavLink 
                to='/' 
                className={({ isActive }) => (isActive ? NavbarCSS.activeMobile : NavbarCSS.inactiveMobile)}>
                <IonIcon 
                  className={ NavbarCSS['nav-logo'] } 
                  icon={ homeOutline } />
                Home
              </NavLink>
            </li>
            <li onClick={ () => setMenuState(false) }>
              <NavLink 
                to='/about' 
                className={({ isActive }) => (isActive ? NavbarCSS.activeMobile : NavbarCSS.inactiveMobile)}>
                <IonIcon 
                  className={ NavbarCSS['nav-logo'] } 
                  icon={ personOutline } />
                About
              </NavLink>
            </li>
            <li onClick={ () => setMenuState(false) }>
              <NavLink 
                to='/projects' 
                className={({ isActive }) => (isActive ? NavbarCSS.activeMobile : NavbarCSS.inactiveMobile)}>
                <IonIcon 
                  className={ NavbarCSS['nav-logo'] } 
                  icon={ codeOutline } />
                Projects
              </NavLink>
            </li>
            <li onClick={ () => setMenuState(false) }>
              <NavLink 
                to='/contact' 
                className={({ isActive }) => (isActive ? NavbarCSS.activeMobile : NavbarCSS.inactiveMobile)}>
                <IonIcon 
                  className={ NavbarCSS['nav-logo'] } 
                  icon={ mailOutline } />
                Contact
              </NavLink>
            </li>
          </ul>
          <span className={ NavbarCSS['version'] }>Version 1.0.0</span>
        </div>
      </div>
    </nav> :
    <nav className={ mobileNav ? NavbarCSS['mobile-nav'] : NavbarCSS['full-nav'] }>
      <NavLink 
        to='/' 
        className={({ isActive }) => (isActive ? NavbarCSS.active : NavbarCSS.inactive)}>
        <div className={ NavbarCSS['circle-shadow'] }>
          <img src={ MainLogo } alt='Logo' width={ 80 } height={ 80 } />
        </div>
      </NavLink>
      <ul>
        <li>
          <NavLink 
            to='/about' 
            className={({ isActive }) => (isActive ? NavbarCSS.active : NavbarCSS.inactive)}>
            About</NavLink></li>
        <li>
          <NavLink 
          to='/projects' 
          className={({ isActive }) => (isActive ? NavbarCSS.active : NavbarCSS.inactive)}>
            Projects</NavLink></li>
        <li>
          <NavLink 
            to='/contact' 
            className={({ isActive }) => (isActive ? NavbarCSS.active : NavbarCSS.inactive)}>
            Contact</NavLink></li>
      </ul>
    </nav>
  );
}

export default Navbar;
