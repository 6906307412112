import React, { useEffect, useState, useCallback } from 'react';
import ProjectNavbarCSS from './ProjectsNavbar.module.css';
import { NavLink, Outlet, useParams, useNavigate } from 'react-router-dom';
import ProjectsData from '../../../data/projects.json';
import WorkplaceData from '../../../data/workplaces.json';
import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';
import { Project } from '../../../interfaces/project.interface';

function ProjectsNavbar() {
  const { workplace, project } = useParams();
  const projects = ProjectsData;
  const [projectData, setProjectData] = useState<Project | null>();
  const navigate = useNavigate();

  const route = useCallback((path: string, exactPath = false) => {
    let fullPath = `./${ path }`; 
    exactPath ? navigate(path) : navigate(fullPath);
  }, [navigate])

  useEffect(() => {
    function getPageData() {
      const result = projects.find(projectItem => (projectItem.workplace === workplace) && (projectItem.path === project))
      return result ? result : null;
    }
    if (workplace && project) {
      const result = getPageData();
      result ? setProjectData(result) : route('/projects', true);
    } else {
      setProjectData(null);
      if (workplace && !WorkplaceData[workplace]) route('/projects', true);
    }
  }, [workplace, project, projects, route])

  const KEYS = Object.keys(WorkplaceData);

  return (
    <>
      {
        !workplace ?
        null : workplace && WorkplaceData[workplace] ?
        <nav className={ ProjectNavbarCSS['nav'] }>
          <ul>
            <li>
              <NavLink 
                to={ `/projects` } 
                className={ ProjectNavbarCSS['sub-nav-link'] }>
                  Workplace
              </NavLink>
            </li>
            <IonIcon className={ ProjectNavbarCSS['chevron-icon'] } icon={ chevronForwardOutline } />
            {
              KEYS.map(key => {
              return (
                workplace === key ?
                <li key={ key }>
                  <NavLink 
                    to={ `/projects/${key}` } 
                    className={ ProjectNavbarCSS['sub-nav-link'] }>
                      { WorkplaceData[key] ?? key }
                  </NavLink>
                </li> : null
              )
              })
            }
            {
              projectData ?
              <>
                <IonIcon className={ ProjectNavbarCSS['chevron-icon'] } icon={ chevronForwardOutline } />
                <li className={ ProjectNavbarCSS['project'] }>
                  { projectData ? projectData.name : null }
                </li>
              </> : null
            }
          </ul>
        </nav> : null
      }
      <Outlet />
    </>
  );
}

export default ProjectsNavbar;
