import React, { useEffect, useState } from "react"
import WorkplaceDetailsCSS from "./WorkplaceDetails.module.css"
import { useNavigate, useParams } from "react-router-dom"
import {
	chevronForwardOutline,
	logoReact,
	logoAngular,
	logoJavascript,
	logoNodejs,
	logoHtml5,
	logoCss3,
	logoNpm,
	logoFirebase,
	logoIonic,
	bluetoothSharp,
} from "ionicons/icons"
import { IonIcon } from "@ionic/react"
import ProjectsData from "../../data/projects.json"
import WorkplaceData from "../../data/workplaces.json"

function WorkplaceDetails() {
	const { workplace } = useParams()
	const navigate = useNavigate()
	const [projectsExist, setProjectsExist] = useState(false)
	const route = (path: string, name: string, work: string) => {
		let fullPath = `/projects/${work}/${path}`
		navigate(fullPath, {
			state: {
				name: name ?? null,
			},
		})
	}
	const TECH_ICONS = {
		react: logoReact,
		angular: logoAngular,
		javascript: logoJavascript,
		node: logoNodejs,
		html: logoHtml5,
		css: logoCss3,
		npm: logoNpm,
		firebase: logoFirebase,
		ionic: logoIonic,
		bluetooth: bluetoothSharp,
	}

	const projects = ProjectsData

	useEffect(() => {
		projects.forEach((project) => {
			if (project.workplace === workplace) {
				setProjectsExist(true)
			}
		})
	}, [projects, workplace])

	return (
		<div className={WorkplaceDetailsCSS["page-styles"]}>
			<div className={WorkplaceDetailsCSS["content"]}>
				<div className={WorkplaceDetailsCSS["top-section"]}>
					<h1>{workplace && WorkplaceData[workplace]} Projects</h1>
					<div className="tick"></div>
					<span>
						<p className={WorkplaceDetailsCSS["reading-section"]}>
							{projectsExist
								? "Select a tile to see more information on a specific project I've worked on."
								: "There isn't anything to show off right now but hopefully in the future I will have more to share!"}
						</p>
					</span>
				</div>
				<div className={WorkplaceDetailsCSS["bottom-section"]}>
					<div className={WorkplaceDetailsCSS["projects-section"]}>
						{projects.map((project, index) => {
							if (project.workplace !== workplace && workplace !== "all") {
								return null
							}
							return (
								<div
									className={WorkplaceDetailsCSS["project-container"]}
									key={index}
									onClick={() =>
										route(project.path, project.name, project.workplace)
									}
								>
									<div className={WorkplaceDetailsCSS["project-left"]}>
										<div className={WorkplaceDetailsCSS["project-name"]}>
											{project.name}
										</div>
										<div className={WorkplaceDetailsCSS["project-icons"]}>
											{project.icons.map((iconName, index) => {
												return (
													<IonIcon
														className={WorkplaceDetailsCSS["tech-icon"]}
														icon={TECH_ICONS[iconName]}
														key={index}
													/>
												)
											})}
										</div>
									</div>
									<div className={WorkplaceDetailsCSS["project-right"]}>
										<IonIcon
											className={WorkplaceDetailsCSS["chevron-icon"]}
											icon={chevronForwardOutline}
										/>
									</div>
								</div>
							)
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default WorkplaceDetails
