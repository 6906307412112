import React from 'react';
import HomeCSS from './Home.module.css';
import { IonIcon } from '@ionic/react';
import { logoGithub, logoLinkedin, mail } from 'ionicons/icons';
import myResume from '../../Korey-Resume.pdf';

function Home() {
  return (
    <div className={ HomeCSS['page-styles'] }>
        <div className={ HomeCSS['welcome-box'] }>
          <h1 className={ HomeCSS['dev-name'] }>Korey Madden</h1>
          <span className={ HomeCSS['dev-title'] }>Front-End Developer, Sr. Analyst</span>
          <p className={ HomeCSS['description'] }>I'm a front‑end developer focused on creating clean & user‑friendly experiences. I am passionate about learning more technologies to transition into a full-stack developer.</p>
          <a className={ HomeCSS['resume-button'] } href={ myResume } target="_blank" rel="noreferrer">Résumé</a>
        </div>
        <div className={ HomeCSS['social-menu'] }>
          <a href='https://www.linkedin.com/in/korey-madden-41128613b/' className={ HomeCSS['social-link'] } target="_blank" rel="noreferrer">
            <IonIcon className={ HomeCSS['social-logo'] } icon={ logoLinkedin } />
          </a>
          <a href='https://github.com/koreymadden' className={ HomeCSS['social-link'] } target="_blank" rel="noreferrer">
            <IonIcon className={ HomeCSS['social-logo'] } icon={ logoGithub } />
          </a>
          <a href='mailto:maddenkorey@gmail.com' className={ HomeCSS['social-link'] }>
            <IonIcon className={ HomeCSS['social-logo'] } icon={ mail } />
          </a>
        </div>
    </div>
  );
}

export default Home;
