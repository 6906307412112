import React, { useEffect } from 'react';

function Triangles() {
    useEffect(() => {
        createBackground();
        window.addEventListener('resize', createBackground);
    }, []);

    const createBackground = () => {
        const canvas = document.querySelector('canvas');
        const canvasWidth = canvas.width = window.innerWidth;
        const canvasHeight = canvas.height = window.innerHeight;
        const ctx = canvas.getContext('2d');
        const heightScale = 0.866;

        function rnd(min, max) {
            return Math.floor((Math.random() * (max - min + 1)) + min);
        };

        function render() {
            ctx.fillStyle = 'rgb(246,249,252)';
            ctx.fillRect(0, 0, canvasWidth, canvasHeight);
            ctx.lineWidth = 0;

            const hueStart = rnd(0, 0);
            const triSide = 40;
            const halfSide = triSide / 2;
            const rowHeight = Math.floor(triSide * heightScale);
            const columns = Math.ceil(canvasWidth / triSide) + 1;
            const rows = Math.ceil(canvasHeight / rowHeight);

            let col, row;
            for (row = 0; row < rows; row++) {
                const hue = hueStart + (row * 3);

                for (col = 0; col < columns; col++) {

                    let x = col * triSide;
                    const y = row * rowHeight;
                    let clr;

                    if (row % 2 !== 0) {
                        x -= halfSide;
                    }

                    // upward pointing triangle
                    clr = 'hsl(' + hue + ', 0%, ' + rnd(90, 240) + '%)';
                    ctx.fillStyle = clr;
                    ctx.strokeStyle = clr;
                    ctx.beginPath();
                    ctx.moveTo(x, y);
                    ctx.lineTo(x + halfSide, y + rowHeight);
                    ctx.lineTo(x - halfSide, y + rowHeight);
                    ctx.closePath();
                    ctx.fill();
                    //ctx.stroke(); // needed to fill antialiased gaps on edges

                    // downward pointing triangle
                    clr = 'hsl(' + hue + ', 0%, ' + rnd(90, 245) + '%)';
                    ctx.fillStyle = clr;
                    ctx.strokeStyle = clr;
                    ctx.beginPath();
                    ctx.moveTo(x, y);
                    ctx.lineTo(x + triSide, y);
                    ctx.lineTo(x + halfSide, y + rowHeight);
                    ctx.closePath();
                    ctx.fill();
                    //ctx.stroke();

                };
            };
        };

        document.body.appendChild(canvas);

        render();

        // document.body.addEventListener('dblclick', render);
    }

    return (
        <canvas style={{
            position: 'fixed', 
            zIndex: '-1', 
            top: '0', 
            left: '0',
            right: '0',
            bottom: '0',
            overflow: 'hidden',
            maxWidth: '100%',
            height: '100vh'
        }}></canvas>
    );
}

export default Triangles;
